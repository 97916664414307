import * as Yup from "yup"
import { constants } from "config"
import { removeWhiteSpaces } from "config/utils"
import { EmployeeTypesEnum, LocationDocumentTypes } from "config/constants"
import { momentZ } from "config/moment"

const { VALIDATION_REGEX } = constants

const detailsInitialValues = {
	nin: "",
	pin: "",
	city: "",
	type: EmployeeTypesEnum.SECURITY,
	email: "",
	image: null,
	branch: "",
	county: "",
	mobile: "",
	lastName: "",
	password: "",
	supplier: "",
	userName: "",
	clientPin: "",
	firstName: "",
	shortName: "",
	telephone: "",
	postalCode: "",
	addressLine1: "",
	addressLine2: "",
	confirmPassword: "",
}

const qualificationsInitialValues = {
	qualification: "",
	startDate: "",
	finishDate: "",
	reference: "",
	siaImage: "",
}
const siaQualificationsInitialValues = {
	reference: "",
	siaImage: "",
}
const hrInitialValues = {
	nin: "",
	dob: "",
	disclosureSentDate: "",
	povaReturnDate: "",
	povaChecked: false,
	disclosureReturnDate: "",
	visaExpiryDate: "",
	name: "",
	addressLine1: "",
	addressLine2: "",
	county: "",
	city: "",
	telephone: "",
	mobile: "",
	relationship: "",
	postalCode: "",
	gender: "",
	disclosureRef: "",
	disclosureLevel: "",
	sex: "",
	nationality: "",
	maritalStatus: "",
	dependants: "",
	hoursAllowed: "",
	visaType: "",
}
const holidayInitialValues = {
	yearlyEntitlement: 0,
	carriedForwardDays: 0,
	holidayYearStartDate: "",
}
const sicknessInitialValues = {
	employeeService: "",
	startDate: "",
	yearlyEntitlement: "",
}
const termsInitialValues = {
	startDate: "",
	finishDate: "",
	payrollTerm: "",
}

const employeeBansInitialValues = {
	selectOption: "",
	branch: "",
	location: "",
	reason: "",
	from: "",
}
const employeeTrainingWorkInitialValues = {
	branch: "",
	location: "",
}

const EmployeeEquipmentSizesInitialValues = {
	cap: "",
	coat: "",
	waist: "",
	chest: "",
	insideLeg: "",
	dress: "",
	collar: "",
	shoes: "",
	gloves: "",
}
const equipmentsInitialValues = {
	employee: "",
	equipmentType: "",
	serialNumber: "",
	quantity: "",
	issueDate: "",
	returnDate: "",
	checked: "",
	nextCheck: "",
	notes: "",
	audit: "",
}
const equipmentsFormInitialValues = {
	equipments: [],
}
const additionalDetailsInitialValues = {
	utrNumber: "",
	shortCode: "",
}
const bankDetailsInitialValues = {
	accountNumber: "",
	bankName: "",
	accountTitle: "",
	sortCode: "",
}

export const employeeDocumentsInitialValues = {
	title: "",
	description: "",
	link: "",
}

const documentsInitialValues = {
	title: "",
	description: "",
	document: "",
}
const trainingInitialValues = {
	branch: "",
	location: "",
}
const usualWorkInitialValues = {
	branch: "",
	location: "",
}
const employeeBanInitialValues = {
	selectFrom: "",
	branch: "",
	location: "",
	customer: "",
	reason: "",
	from: "",
	startDate: "",
	finishDate: "",
}

const bankDetailsValidationSchema = Yup.object().shape({
	accountNumber: Yup.string().required().label("Account Number"),
	bankName: Yup.string().required().label("Bank Name"),
	accountTitle: Yup.string().required().label("Account Title "),
	sortCode: Yup.string().required().label("Sort code"),
})

const qualificationValidationSchema = Yup.object().shape({
	qualification: Yup.string().required().label("Qualification"),
	startDate: Yup.string().label("Start Date"),
	finishDate: Yup.string().label("Finish Date"),
	reference: Yup.string().label("Reference"),
	siaImage: Yup.string().label("SIA Image"),
})

const siaQualificationValidationSchema = Yup.object().shape({
	reference: Yup.string().label("Reference"),
	siaImage: Yup.string().label("SIA Image"),
})

export const employeeDocumentValidationSchema = Yup.object().shape({
	title: Yup.string().required().label("Title"),
	description: Yup.string().required().label("Description"),
	link: Yup.string().required().label("Document"),
})

const documentsValidationSchema = Yup.object().shape(
	{
		title: Yup.string().required().label("Title"),
		description: Yup.string().required().label("Description"),
		link: Yup.string().required().label("Document"),
		type: Yup.string().required().label("Type"),
		startDate: Yup.string()
			.when("type", {
				is: (value: any) => value === LocationDocumentTypes.ASSIGNMENT_INSTRUCTIONS,
				then: rule => rule.required(),
			})
			.notRequired()
			.nullable()
			.label("startDate"),
		isApproved: Yup.boolean()
			.when("type", {
				is: (value: any) => value === LocationDocumentTypes.ASSIGNMENT_INSTRUCTIONS,
				then: rule => rule.required(),
			})
			.notRequired()
			.nullable()
			.label("isApproved"),
		endDate: Yup.string()
			.when("type", {
				is: (value: any) => value === LocationDocumentTypes.ASSIGNMENT_INSTRUCTIONS,
				then: rule => rule.required(),
			})
			.notRequired()
			.nullable()
			.label("endDate"),
	},
	[
		["startDate", "startDate"],
		["endDate", "endDate"],
	],
)

const additionalDetailsValidationSchema = Yup.object().shape({
	utrNumber: Yup.number().required().label("UTR Number"),
	shortCode: Yup.string().label("Short Code"),
})
const EmployeeEquipmentSizesValidationSchema = Yup.object().shape({
	cap: Yup.string().required().label("Hard Hat"),
	coat: Yup.string().required().label("High Visibility Jacket"),
	waist: Yup.string().required().label("Waist"),
	chest: Yup.string().required().label("Chest"),
	insideLeg: Yup.string().required().label("Inside Leg"),
	dress: Yup.string().optional().label("Dress"),
	collar: Yup.string().required().label("Collar"),
	shoes: Yup.string().required().label("Shoes"),
	gloves: Yup.string().required().label("Gloves"),
})
const employeeBansValidationSchema = Yup.object().shape({
	branch: Yup.string().required().label("Branch"),
	location: Yup.string().required().label("Location"),
	reason: Yup.string().required().label("Reason"),
	from: Yup.string().required().label("From"),
})
const employeeTrainingWorkValidationSchema = Yup.object().shape({
	branch: Yup.string().required().label("Branch"),
	location: Yup.string().required().label("Location"),
})

const termsValidationSchema = Yup.object().shape({
	startDate: Yup.string().required().label("Start Date"),
	finishDate: Yup.string().nullable().label("Finish Date"),
	payrollTerm: Yup.string().required().label("Payroll Term"),
})
const sicknessValidationSchema = Yup.object().shape({
	sicknessYearStartDate: Yup.string().required().label("Sickness Start Date"),
	entitledDays: Yup.number().required().label("Entitled Days"),
})
const holidayValidationSchema = Yup.object().shape({
	holidayYearStartDate: Yup.string().required().label("Holiday Yearly Start Date"),
	yearlyEntitlement: Yup.string().required().label("Yearly Entitlement"),
	carriedForwardDays: Yup.string().required().label("Carried Forward Days"),
})
const hrValidationSchema = Yup.object().shape(
	{
		nin: Yup.string().required().label("NI Number").matches(VALIDATION_REGEX.nin, "Invalid NI Number"),
		dob: Yup.string().nullable().label("Date of Birth"),
		gender: Yup.string().notRequired().label("Gender"),
		nationality: Yup.string().notRequired().label("Nationality"),
		maritalStatus: Yup.string().notRequired().label("Marital Status"),
		dependants: Yup.string().notRequired().label("Dependants"),
		visaExpiryDate: Yup.string().nullable().label("Visa Expiry Date"),
		disclosureRef: Yup.string().label("Disclosure Ref"),
		disclosureLevel: Yup.string().label("Disclosure Level"),
		disclosureSentDate: Yup.string().nullable().label("Disclosure Sent Date"),
		povaChecked: Yup.boolean().label("Pova Checked"),
		povaReturnDate: Yup.string().nullable().label("Pova Return Date"),
		disclosureReturnDate: Yup.string().nullable().label("Disclosure Return Date"),
		name: Yup.string().notRequired().label("Name"),
		relationship: Yup.string().notRequired().label("Relationship"),
		addressLine1: Yup.string().notRequired().label("Address Line 1"),
		addressLine2: Yup.string().label("Address Line 2"),
		county: Yup.string().notRequired().label("County").nullable(),
		city: Yup.string().notRequired().label("City"),
		postalCode: Yup.string()
			.label("Postal code")
			.notRequired()
			.nullable()
			.when("postalCode", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.postalCode, "Invalid Postal Code"),
			}),
		telephone: Yup.string()
			.label("Telephone")
			.nullable()
			.notRequired()
			.when("telephone", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.phone, "Invalid Telephone Number"),
			}),
		mobile: Yup.string()
			.label("Mobile")
			.nullable()
			.notRequired()
			.when("mobile", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.mobile, "Invalid Mobile Number"),
			}),
		hoursAllowed: Yup.number()
			.positive()
			.integer()
			.transform(value => (isNaN(value) ? undefined : value))
			.label("Hours Allowed"),
		visaType: Yup.string().label("Visa Type"),
	},
	[
		["telephone", "telephone"],
		["mobile", "mobile"],
		["postalCode", "postalCode"],
	],
)

const servicesInitialValues = {
	startDate: "",
	finishDate: "",
	staffNumber: "",
	payGroup: "",
	minHours: "",
	maxHours: "",
	averagedOver: "",
	minEvents: "",
	maxEvents: "",
	proRata: "",
	additionalContractHours: "",
}

const servicesValidationSchema = Yup.object().shape({
	startDate: Yup.string().required().label("Start Date"),
	finishDate: Yup.string().nullable().label("Finish Date"),
	staffNumber: Yup.string().label("Staff Number"),
	payGroup: Yup.string().nullable().label("Pay Group"),
	minHours: Yup.number()
		.positive()
		.integer()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Min Hours Per Week"),
	maxHours: Yup.number()
		.positive()
		.integer()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Min Hours Per Week"),
	averagedOver: Yup.number()
		.positive()
		.integer()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Averaged Over"),
	minEvents: Yup.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Min Events Per Week"),
	maxEvents: Yup.number()
		.positive()
		.integer()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Max Events Per Week"),
	proRata: Yup.number()
		.positive()
		.integer()
		.transform(value => (isNaN(value) ? undefined : value))
		.nullable()
		.label("Pro Rata"),
	additionalContractHours: Yup.number()
		.positive()
		.integer()
		.transform(value => (isNaN(value) ? undefined : value))
		.label("Additional Contract Hours"),
})
const equipmentsValidationSchema = Yup.object().shape({
	serialNumber: Yup.string().label("Size/Sr No."),
	equipmentType: Yup.string().required().label("Equipment"),
	quantity: Yup.string().required().label("Quantity"),
	issueDate: Yup.string().required().label("Issued Date"),
	returnDate: Yup.string().label("Return Date"),
	checked: Yup.string().label("Checked Date"),
	nextCheck: Yup.string().label("Next Check"),
	notes: Yup.string().label("Notes"),
	audit: Yup.string(),
	customer: Yup.string().required().label("Customer"),
})

const detailsValidationSchema = Yup.object().shape(
	{
		nin: Yup.lazy(value => {
			if (value && value === "#") return Yup.string().notRequired()
			return Yup.string().label("NI Number").matches(VALIDATION_REGEX.nin, "Invalid NI Number")
		}),
		branch: Yup.string()
			.when("supplier", {
				is: (value: any) => !value?.length,
				then: rule => rule.required(),
			})
			.notRequired()
			.nullable()
			.label("Branch"),
		supplier: Yup.string()
			.when("Supplier", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.mongoId, "Invalid Supplier"),
			})
			.notRequired()
			.nullable()
			.label("Supplier"),
		firstName: Yup.string().required().label("First name"),
		lastName: Yup.string().required().label("Last name"),
		shortName: Yup.string().required().label("Short Name"),
		type: Yup.string().required().label("Type"),
		addressLine1: Yup.string().label("Address line 1"),
		addressLine2: Yup.string().label("Address Line 2"),
		city: Yup.string()
			.when("city", {
				is: (value: any) => value?.length,
				then: rule => rule.min(3),
			})
			.label("City"),
		county: Yup.string()
			.when("county", {
				is: (value: any) => value?.length,
				then: rule => rule.min(3),
			})
			.label("County"),
		postalCode: Yup.string()
			.label("Postal code")
			.nullable()
			.notRequired()
			.transform(value => removeWhiteSpaces(value))
			.when("postalCode", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.postalCode, "Invalid Postal Code"),
			}),
		telephone: Yup.string()
			.label("Telephone")
			.nullable()
			.notRequired()
			.when("telephone", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.phone, "Invalid Telephone Number"),
			}),
		mobile: Yup.string().label("Mobile"),
		email: Yup.string().email().label("Email"),
		thumbnail: Yup.string().label("Upload Image"),
		// userName: Yup.lazy(value => {
		// 	if (value && value === "#") return Yup.string().notRequired()
		// 	return Yup.string()
		// 		.required("Username is a required field")
		// 		.min(6, "Username must be between 6-20 characters long")
		// 		.matches(
		// 			VALIDATION_REGEX.usernameCheckI,
		// 			"Username must contains only letters, numbers, dots, and underscores",
		// 		)
		// 		.matches(VALIDATION_REGEX.usernameCheckII, "Username can not contain consecutive dots or underscores")
		// 		.matches(VALIDATION_REGEX.usernameCheckIII, "Username can not start or end with dot or underscores")
		// 		.max(20, "Username must be between 6-20 characters long")
		// }),
		password: Yup.lazy(value => {
			if (value && value === "#") return Yup.string().notRequired()
			return Yup.string()
				.required()
				.min(6, "Password is too short - should be 6 chars minimum.")
				.label("Password")
		}),
		confirmPassword: Yup.lazy(value => {
			if (value && value === "#") return Yup.string().notRequired()
			return Yup.string()
				.required()
				.oneOf([Yup.ref("password"), null], "Passwords does not match")
				.label("Confirm Password")
		}),
		clientPin: Yup.string()
			.nullable()
			.notRequired()
			.when("clientPin", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(/^\d+$/, "Client PIN can only be number"),
			})
			.label("Client Pin"),
	},
	[
		["telephone", "telephone"],
		["userName", "userName"],
		["password", "password"],
		["confirmPassword", "confirmPassword"],
		["postalCode", "postalCode"],
		["county", "county"],
		["city", "city"],
		["branch", "branch"],
		["supplier", "supplier"],
		["clientPin", "clientPin"],
	],
)

const employeeTrainingValidationSchema = Yup.object().shape({
	branch: Yup.string().required().label("Branch"),
	location: Yup.string().required().label("Location"),
})
const employeeUsualWorkValidationSchema = Yup.object().shape({
	branch: Yup.string().required().label("Branch"),
	location: Yup.string().required().label("Location"),
})
const employeeBanValidationSchema = Yup.object().shape(
	{
		branch: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired()
			return Yup.string().required("Branch is required field")
		}),
		location: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired()
			return Yup.string().required("Location is required field")
		}),
		customer: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired()
			return Yup.string().required("Customer is required field")
		}),
		selectOption: Yup.string().label("Ban From"),
		reason: Yup.string().label("Reason"),
		from: Yup.string().label("From"),
		startDate: Yup.string().required().label("Start Date"),
		finishDate: Yup.string().label("Finish Date"),
		isActive: Yup.string().label("Is Active"),
	},
	[
		["branch", "branch"],
		["location", "location"],
		["customer", "customer"],
	],
)

const LdnInitialValues = {
	documentPath: "",
	startDate: momentZ().toISOString(),
	endDate: momentZ().toISOString(),
	reference: "",
}

const LdnValidationSchema = Yup.object().shape({
	documentPath: Yup.string().required().label("LDN Document"),
	startDate: Yup.string().required().label("LDN Start Date"),
	endDate: Yup.string().required().label("LDN End Date"),
	reference: Yup.string().required().label("LDN Reference"),
})

const pbCodeDetailsSchema = Yup.object().shape({
	paycodes: Yup.array().of(
		Yup.object().shape({
			pbcode: Yup.string().required(),
			location: Yup.string().required(),
		}),
	),
})

export {
	siaQualificationsInitialValues,
	siaQualificationValidationSchema,
	detailsValidationSchema,
	detailsInitialValues,
	servicesInitialValues,
	servicesValidationSchema,
	hrInitialValues,
	hrValidationSchema,
	holidayInitialValues,
	holidayValidationSchema,
	sicknessInitialValues,
	sicknessValidationSchema,
	termsInitialValues,
	termsValidationSchema,
	employeeBansInitialValues,
	employeeBansValidationSchema,
	employeeTrainingWorkInitialValues,
	employeeTrainingWorkValidationSchema,
	equipmentsInitialValues,
	equipmentsFormInitialValues,
	EmployeeEquipmentSizesInitialValues,
	EmployeeEquipmentSizesValidationSchema,
	additionalDetailsInitialValues,
	bankDetailsInitialValues,
	additionalDetailsValidationSchema,
	documentsInitialValues,
	documentsValidationSchema,
	qualificationsInitialValues,
	qualificationValidationSchema,
	bankDetailsValidationSchema,
	equipmentsValidationSchema,
	trainingInitialValues,
	employeeTrainingValidationSchema,
	usualWorkInitialValues,
	employeeUsualWorkValidationSchema,
	employeeBanInitialValues,
	employeeBanValidationSchema,
	LdnValidationSchema,
	LdnInitialValues,
	pbCodeDetailsSchema,
}
